<template>
  <b-input
    :value="values[field.id]"
    size="is-small"
    type="password"
    :disabled="field.properties.basic.isDisabled"
    :password-reveal="field.properties.basic.isPasswordVisible"
    placeholder="*******"
    @input="$emit('update', {
      value: $event
    })"
  />
</template>

<script >
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  return {};
};
export default __sfc_main;
</script>
